import React, { useEffect, useState } from 'react';
import AppLayout from "../../Layout/AppLayout";
import { connect, useDispatch } from "react-redux";
import { authUserType } from "../../../redux/modules/userReducer";
import {
    AddCorrectiveAction,
    GetCorrectiveActionLogByClientID,
    UpdateCorrectiveAction
} from "../../../actions/correctiveAction";
import { apiResponseType, userType } from "../../../config/commonTypes";
import {
    GET_CORRECTIVE_ACTION_LOG_FAILURE,
    GET_CORRECTIVE_ACTION_LOG_REQUEST,
    GET_CORRECTIVE_ACTION_LOG_SUCCESS,
    GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_FAILURE,
    GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_REQUEST,
    GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_SUCCESS
} from "../../../constants";
import { BootyPagination } from "../../../components/pagination/BootyPagination";
import DataTable, { ConditionalStyles, TableStyles } from "react-data-table-component";
import { ICorrectiveActionLogType } from "../../../redux/modules/correctiveActionReducer";
import moment from "moment";
import CustomModal from "../../../components/modal/CustomModal";
import { getAllUserByClientId } from "../../../actions/userAction";
import AddManualCorrectiveActionForm, { IAddCorrectiveActionFormType } from "./AddManualCorrectiveActionForm";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { jsPDF } from "jspdf";
import logoImage from "../../../assets/img/logo/logo.png";
import autoTable from "jspdf-autotable";
import AddCorrectiveActionForm, { IAddCorrectiveActionType } from "./AddCorrectiveActionForm";
import { useLocation, useNavigate } from "react-router-dom";
import AccessControl from '../../../config/AccessControl';
import { AccessControlType, UserPermissionsConstant } from '../../../constants/constants';
import _ from "lodash";
import { clientType } from '../../../redux/modules/clientManagementReducer';
import { sortByDate, sortByString,compareDates,toLocalformatDate } from "../../../config/commonMethods";

interface IPropsTypes {
    user?: authUserType
    isLoading?: boolean;
    correctiveActionLogs?: ICorrectiveActionLogType[] | [];
    clientId?: number;
    responsiblePersons?: userType[] | [];
    selectedClientDetails?: clientType;
}

interface IFilterType {
    startDateFrom: string;
    startDateTo: string;
    auditName: string;
    closeDateFrom: string;
    closeDateTo: string;
    status: string;
}
const initialValues: IFilterType = {
    startDateFrom: '',
    startDateTo: '',
    auditName: '',
    closeDateFrom: '',
    closeDateTo: '',
    status: '-2',
}
function CorrectiveActionLog({
    user,
    isLoading,
    correctiveActionLogs,
    clientId,
    responsiblePersons = [],
    selectedClientDetails
}: IPropsTypes) {
    const dispatch = useDispatch();
    const [dataTableFormat, setDataTableFormat] = useState<any>();
    const [isOpenCreateCorrectiveAction, setIsOpenCreateCorrectiveAction] = useState<boolean>();
    const [isOpenUpdateCorrectiveAction, setIsOpenUpdateCorrectiveAction] = useState<boolean>();
    const [filterValues, setFilterValues] = useState<IFilterType>(initialValues);
    const [selectedCorrectiveAction, setSelectedCorrectiveAction] = useState<ICorrectiveActionLogType>();
    const [loading, setLoading] = useState<boolean>(false);
    const url = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (url.pathname === '/create-corrective-action')
            setIsOpenCreateCorrectiveAction(true);
        else setIsOpenCreateCorrectiveAction(false);
    }, [url]);

    useEffect(() => {
        if (clientId) {
            fetchCorrectiveActionLogByClientID(clientId,filterValues.status);
            fetchResponsiblePersonsByClientID(clientId);
        }
    }, [clientId,filterValues.status]);

    useEffect(() => {
        setFilterValues((prevData) => ({
            ...prevData,
            startDateFrom: "",
            auditName: "",
            closeDateFrom: "",
            closeDateTo: "",
            startDateTo:""
          }));
    }, [filterValues.status]);


    const fetchResponsiblePersonsByClientID = (clientId: number) => {
        dispatch({
            type: GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_REQUEST,
        });
        getAllUserByClientId(clientId,
            (users: userType[]) => {
                dispatch({
                    type: GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_SUCCESS,
                    payload: users
                });
            },
            (error: apiResponseType) => {
                dispatch({
                    type: GET_RESPONSIBLE_USER_IN_CORRECTIVE_ACTION_FAILURE,
                    payload: error.data
                });
            });
    }

    const fetchCorrectiveActionLogByClientID = (clientId: number,status:any) => {
        setLoading(true);
        dispatch({
            type: GET_CORRECTIVE_ACTION_LOG_REQUEST,
        });
        GetCorrectiveActionLogByClientID(clientId,status,
            (response: apiResponseType) => {
                dispatch({
                    type: GET_CORRECTIVE_ACTION_LOG_SUCCESS,
                    payload: response.data
                });
                setLoading(false);
            }, (error: apiResponseType) => {
                dispatch({
                    type: GET_CORRECTIVE_ACTION_LOG_FAILURE,
                    payload: error.data
                });
                setLoading(false);
            });
    }

    useEffect(() => {
        if (correctiveActionLogs) {
            setDataTableFormat(formatToDataTableFormat(correctiveActionLogs))
        }
    }, [correctiveActionLogs]);

    const customStyles: TableStyles = {
        headRow: {
            style: { backgroundColor: "#f2f2f2", border: "1px solid #d9dee3" },
        },
        rows: {
            style: { border: "1px solid #d9dee3", cursor: 'pointer' },
        },
    };

    const conditionalRowStyles: any = [
        {
            when: (row: any) => !!row?.correctiveActionLogId,
            style: (row: any) => ({
                backgroundColor: row?.isClosed ? '#E6E6F5' : '#fcc',
            }),
        },
    ];

    const compareDates = (dateA: any , dateB: any ) => {
        // Handle empty values
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        // Compare the dates using Moment.js
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    };

    const compareDateStart = (rowA: any, rowB: any) => {
        // Parse the date strings using Moment.js
        const dateA = rowA.dataTableStartDate ? moment(rowA.dataTableStartDate, 'DD/MM/YYYY') : null;
        const dateB = rowB.dataTableStartDate ? moment(rowB.dataTableStartDate, 'DD/MM/YYYY') : null;
        return compareDates(dateA, dateB); 
        
    };

    const compareDateClosed = (rowA: any, rowB: any) => {
        // Parse the date strings using Moment.js
        const dateA = rowA.dataTableCompleteDate ? moment(rowA.dataTableCompleteDate, 'DD/MM/YYYY') : null;
        const dateB = rowB.dataTableCompleteDate ? moment(rowB.dataTableCompleteDate, 'DD/MM/YYYY') : null;

        return compareDates(dateA, dateB);
    };

    const columns: any = [
        {
            name: "Start Date",
            selector: (row: any) => row.dataTableStartDate,
            sortable: true,
            width: '110px',
            sortFunction: compareDateStart,
        }, {
            name: "Audit",
            selector: (row: any) => row.dataTableAuditName,
            sortable: true,
            minWidth: "200px",
            wrap: true
        },
        {
            name: "Criteria",
            selector: (row: any) => row.dataTableCriteria,
            sortable: true,
            minWidth: "350px",
            wrap: true
        },
        {
            name: "Findings",
            selector: (row: any) => row.dataTableFindings,
            sortable: true,
            minWidth: "250px",
            wrap: true,
        },
        {
            name: "Corrective Action",
            selector: (row: any) => row.dataTableCorrectiveActions,
            sortable: true,
            minWidth: "250px",
            wrap: true,
        },
        {
            name: "Person Responsible",
            selector: (row: any) => row.dataTableResponsibleUser,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Date Closed",
            selector: (row: any) => row.dataTableCompleteDate,
            sortable: true,
            width: '120px',
            sortFunction: compareDateClosed
        },

    ];

    const formatToDataTableFormat = (correctiveActions: ICorrectiveActionLogType[]) => {
        let dataTableArray: any = [];
        correctiveActions.forEach((action: ICorrectiveActionLogType) => {
            let dataTable = {
                //dataTableStartDate: moment(action.startDate).format('DD/MM/YYYY'),
                dataTableStartDate:toLocalformatDate(action.startDate),
                startDateForOrder: action.startDate,
                dataTableAuditName: action.auditName,
                dataTableCriteria: action.criteria,
                dataTableFindings: action.findings,
                dataTableCorrectiveActions: action.correctiveActions,
                dataTableResponsibleUser: action.responsibleUserFullName,
                //dataTableCompleteDate: action.completeDate ? moment(action.completeDate).format('DD/MM/YYYY') : "",
                dataTableCompleteDate: action.completeDate ? toLocalformatDate(action.completeDate) : "",
                completeDateForOrder:action.completeDate,
                ...action
            }
            dataTableArray.push(dataTable);
        });
        const openCorrectiveAction = dataTableArray.filter((data: any) => !data?.isClosed);
        const closedCorrectiveAction = dataTableArray.filter((data: any) => data?.isClosed);
        return [..._.orderBy(openCorrectiveAction,['startDateForOrder'] ,['desc']),
         ..._.orderBy(closedCorrectiveAction,['completeDateForOrder'] ,['desc'])];
    }

    const addManualCorrectiveAction = (correctiveActionData: IAddCorrectiveActionFormType) => {
        const data = {
            clientId: clientId,
            isComplies: 0,
            ...correctiveActionData
        };
        navigate('/corrective-action-log');
        AddCorrectiveAction(data,
            (response: apiResponseType) => {
                clientId && fetchCorrectiveActionLogByClientID(clientId,filterValues.status);
                toast.success(response?.message ? response.message : "Successfully added corrective action");
            },
            (response: apiResponseType) => {
                toast.error(response?.data ? response.data : "Failed to add corrective action");
            });

    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: IFilterType) => {
            setFilterValues(values);
            const isLoadFiltered = Object.values(values).filter(f => f !== '').length > 0;
            if (!isLoadFiltered && correctiveActionLogs) {
                setDataTableFormat(formatToDataTableFormat(correctiveActionLogs));
            } else {
                correctiveActionLogs &&
                    setDataTableFormat(formatToDataTableFormat(filterDatas(correctiveActionLogs, values)));
                correctiveActionLogs //&&                    
            }
        }
    });

    const filterDatas = (
      correctiveActionLogsP: ICorrectiveActionLogType[] | [],
      filterValuesP: IFilterType
    ): ICorrectiveActionLogType[] => {
      const isLoadFiltered =
        Object.values(filterValuesP).filter((f) => f !== "").length > 0;
        
      if (isLoadFiltered) {
        
        const cloneALlOriginalCorrectiveActionLogs: ICorrectiveActionLogType[] =
          correctiveActionLogsP && correctiveActionLogsP?.length > 0
            ? [...correctiveActionLogsP]
            : [];

        const filterCorrectiveActionLogs =
          cloneALlOriginalCorrectiveActionLogs?.filter(
            (correctiveAction: ICorrectiveActionLogType) =>
              (filterValuesP?.startDateFrom
                ? correctiveAction.startDate
                  ? moment(
                      correctiveAction.startDate,
                      "YYYY-MM-DD"
                    ).isSameOrAfter(filterValuesP?.startDateFrom)
                  : false
                : true) &&
              (filterValuesP?.startDateTo
                ? correctiveAction.startDate
                  ? moment(
                      correctiveAction.startDate,
                      "YYYY-MM-DD"
                    ).isSameOrBefore(filterValuesP?.startDateTo)
                  : false
                : true) &&
              (filterValuesP?.closeDateFrom
                ? correctiveAction.completeDate
                  ? moment(
                      correctiveAction.completeDate,
                      "YYYY-MM-DD"
                    ).isSameOrAfter(filterValuesP?.closeDateFrom)
                  : false
                : true) &&
              (filterValuesP?.closeDateTo
                ? correctiveAction?.completeDate
                  ? moment(
                      correctiveAction.completeDate,
                      "YYYY-MM-DD"
                    ).isSameOrBefore(filterValuesP?.closeDateTo)
                  : false
                : true) &&
              (filterValuesP?.auditName
                ? correctiveAction?.auditName
                  ? correctiveAction?.auditName
                      .toLowerCase()
                      .includes(filterValuesP?.auditName?.toLowerCase())
                          : false
                         : true) 
              );          
        return filterCorrectiveActionLogs;
      }
      return correctiveActionLogsP;
    };

    const convertToBinary = (status: any) => {
        switch (status.toString()) {
            case '-1':
                return null;
            case '1':
                return true;
            case '0':
                return false;
            default:
                return null;
        }
    }

    const [sortedData, setSortedData] = useState<any>([]);
    const generatePDF = () => {
        const printAbleData = 
            !_.isEmpty(sortedData) ?  filterDatas(sortedData, filterValues)
            :
            correctiveActionLogs && filterDatas(dataTableFormat, filterValues);
        if (_.isEmpty(printAbleData)) {
            toast.info("There is no data to print");
            return;
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; //// portrait or landscape
        var doc = new jsPDF(orientation, unit, size);

        doc.addImage(logoImage, 'PNG', 10, 10, 70, 70);
        doc.setFont("times");
        doc.setTextColor(65, 144, 197);
        doc.text('GIA+ Quality Assurance system', 100, 30);
        doc.text('__________________________', 100, 40);
        doc.setTextColor(0, 0, 0);
        doc.text('Corrective Action Log', 100, 60);

        autoTable(doc, {
            head: [],
            margin: {
              top: 80,
            },
            theme:'plain',
            body: [
              ['Client', selectedClientDetails?.companyName ? selectedClientDetails?.companyName :'']
            ],
            columnStyles: {
              0:{ cellWidth: 80, textColor:'#3F3F3F'},
              1:{ overflow: "linebreak",minCellWidth:100, textColor:'#6D6D6D'},
            }
          });
          
        const headers: string[] = [
            "Date",
            "Audit",
            "Criteria",
            "Findings",
            "Corrective Action",
            "Person Responsible",
            "Date Closed",
        ];
        const headKey: string[] = [
            "startDate",
            "auditName",
            "criteria",
            "findings",
            "correctiveActions",
            "responsibleUserFullName",
            "completeDate",
        ];
        let data: any = [];
        
        printAbleData?.forEach((row: any) => {
            let rowData: any[] = [];
            headKey?.forEach((key: string) => {
                if (key === "startDate" || key === "completeDate") {
                    row[key] && rowData.push(toLocalformatDate(row[key]));
                } else rowData.push(row[key]);
            });
            data.push(rowData);
            rowData = [];
        });
            
        autoTable(doc, {
            head: [headers],
            margin: {
                top: 100,
            },
            body: data,
            columnStyles:{
            0:{ overflow: "linebreak", minCellWidth: 60 },
            1:{overflow: "linebreak",minCellWidth:60},
            2:{minCellWidth:55},
            3:{overflow: "linebreak",minCellWidth:100},
            4:{overflow: "linebreak",minCellWidth:120},
            5:{overflow: "linebreak",minCellWidth:80},
            6:{minCellWidth:60},
            }
        });
        doc.save("corrective-action-log.pdf");
    }

    const onRowClicked = (row: any) => {
        setIsOpenUpdateCorrectiveAction(true);
        setSelectedCorrectiveAction(row);
    }

    const addCorrectiveAction = (correctiveActionData: IAddCorrectiveActionType) => {
        const data = {
            ...correctiveActionData,
            correctiveActionLogId: selectedCorrectiveAction?.correctiveActionLogId,
            localTemplateHeaderId: selectedCorrectiveAction?.localTemplateHeaderId,
            localTemplateCriteriaId: selectedCorrectiveAction?.localTemplateCriteriaId,
            isComplies: selectedCorrectiveAction?.isComplies,
            findings: selectedCorrectiveAction?.findings,
            customCriteria: selectedCorrectiveAction?.criteria,
            clientId: clientId,
        };
        setIsOpenUpdateCorrectiveAction(false);
        UpdateCorrectiveAction(data,
            (response: apiResponseType) => {
                clientId && fetchCorrectiveActionLogByClientID(clientId,filterValues.status);
                toast.success(response?.message ? response.message : "Successfully added corrective action");
            },
            (response: apiResponseType) => {
                toast.error(response?.data ? response.data : "Failed to add corrective action");
            });
    }

    return (
        <AppLayout>
            <>
                {isOpenCreateCorrectiveAction && (
                    <CustomModal
                        size="lg" isClose={!isOpenCreateCorrectiveAction}
                        title="Manual corrective action"
                        onClose={() => navigate('/corrective-action-log')}>

                        <>
                            <AddManualCorrectiveActionForm
                                onSubmit={(data: IAddCorrectiveActionFormType) => addManualCorrectiveAction(data)}
                                responsiblePerson={responsiblePersons} />
                        </>
                    </CustomModal>
                )}
                {isOpenUpdateCorrectiveAction && (
                    <CustomModal
                        size="lg" isClose={!isOpenUpdateCorrectiveAction}
                        title="Corrective action"
                        onClose={() => setIsOpenUpdateCorrectiveAction(false)}>

                        <>
                            <AddCorrectiveActionForm
                                correctiveActionData={selectedCorrectiveAction}
                                onSubmit={(data: IAddCorrectiveActionType) => addCorrectiveAction(data)}
                                responsiblePerson={responsiblePersons} 
                                onClose={() => setIsOpenUpdateCorrectiveAction(false)}
                                />
                        </>
                    </CustomModal>
                )}
                <div className="row">
                    <div className="col-md">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 d-flex align-items-center">
                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a target="_self">
                                        Home
                                    </a>
                                </li>

                                <li className="breadcrumb-item d-flex align-items-center">
                                    <a> Corrective Action Log</a>
                                </li>

                                <li className="breadcrumb-item d-flex align-items-center active">
                                    Corrective Actions
                                </li>

                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        <div className="accordion" id="accordionExample">
                            <div className="card accordion-item shadow-sm active">
                                <h6 className="accordion-header" id="headingOne">
                                    <button type="button" className="accordion-button border-bottom p-3"
                                        data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="true"
                                        aria-controls="accordionOne">
                                        Search Filters
                                    </button>
                                </h6>
                                <form onSubmit={formik.handleSubmit}
                                    className="w-100"
                                    method={'POST'}>

                                    <div id="accordionOne" className="accordion-collapse collapse show"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body p-3 pt-2">
                                            <div className="row">
                                                <div className="col">
                                                    <label className="col-form-label text-capitalize fw-semibold">
                                                        Start Date From
                                                    </label>
                                                    <div className="col-md">
                                                        <input
                                                            name="startDateFrom"
                                                            onChange={(e: any) => {
                                                                formik.handleChange(e);
                                                                formik.submitForm();
                                                            }}
                                                            value={filterValues.startDateFrom}
                                                            className="form-control form-control-sm" type="date" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <label className="col-form-label text-capitalize fw-semibold">
                                                        Start Date To
                                                    </label>
                                                    <div className="col-md">
                                                        <input name="startDateTo"
                                                            onChange={(e: any) => {
                                                                formik.handleChange(e);
                                                                formik.submitForm();
                                                            }}
                                                            value={filterValues.startDateTo}
                                                            className="form-control form-control-sm" type="date" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <label className="col-form-label text-capitalize fw-semibold">
                                                        Audit
                                                    </label>
                                                    <input
                                                        name="auditName"
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        value={filterValues.auditName}
                                                        className="form-control form-control-sm" type="text" />
                                                </div>
                                                <div className="col-2 ps-0 d-flex align-items-end justify-content-end">

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label className="col-form-label text-capitalize fw-semibold">
                                                        Closed Date From
                                                    </label>
                                                    <div className="col-md">
                                                        <input
                                                            name="closeDateFrom"
                                                            onChange={(e: any) => {
                                                                formik.handleChange(e);
                                                                formik.submitForm();
                                                            }}
                                                            value={filterValues.closeDateFrom}
                                                            className="form-control form-control-sm" type="date" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <label className="col-form-label text-capitalize fw-semibold">
                                                        Closed Date To
                                                    </label>
                                                    <div className="col-md">
                                                        <input
                                                            name="closeDateTo"
                                                            onChange={(e: any) => {
                                                                formik.handleChange(e);
                                                                formik.submitForm();
                                                            }}
                                                            value={filterValues.closeDateTo}
                                                            className="form-control form-control-sm" type="date" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <label className="col-form-label text-capitalize fw-semibold">
                                                        Status
                                                    </label>
                                                    <select id="status"
                                                        name={"status"}
                                                        onChange={(e: any) => {
                                                            formik.handleChange(e);
                                                            formik.submitForm();
                                                        }}
                                                        className="form-select form-select-sm">
                                                        <option value={"2"}>&nbsp;</option>
                                                        <option value={'-1'}>Pending</option>
                                                        <option value={'0'}>Open</option>
                                                        <option value={'-2'} selected>Open and Pending</option>
                                                        <option value={'1'}>Closed</option>
                                                    </select>
                                                </div>
                                            <div className="col-2 ps-0 d-flex align-items-end justify-content-end">
                                                <div
                                                    className="py-3 pb-0 d-flex flex-wrap justify-content-end d-grid gap-2">
                                                   <AccessControl
                                                        type={AccessControlType.DISABLE}
                                                        allowedPermissions={[
                                                        UserPermissionsConstant.ViewPrintAudit,
                                                        UserPermissionsConstant.MimicAccess
                                                        ]}
                                                    >
                                                    <button type="button"
                                                            onClick={() =>generatePDF()}
                                                            className="btn btn-outline-secondary btn-sm">
                                                        <span className="tf-icons bx bx-printer"></span>
                                                        Print
                                                    </button>
                                                    </AccessControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card shadow-sm mt-4">
                        <div
                            className="d-flex justify-content-between align-items-center card-header mb-0 border-bottom p-3">
                            <div>
                                <h6 className="m-0">Corrective Actions log</h6>
                            </div>
                            <div>
                            <AccessControl
                                type={AccessControlType.DISABLE}
                                allowedPermissions={[
                                UserPermissionsConstant.PerformAudit,
                                UserPermissionsConstant.MimicAccess
                                ]}
                            >
                                <button onClick={()=>navigate('/create-corrective-action')}
                                        type="button" className="btn btn-primary btn-sm">
                                    Add Corrective Action
                                </button>
                                </AccessControl>
                            </div>
                        </div>
                        <div className="card-body p-3">
                            <div className="table-responsive text-nowrap">
                                {
                                   loading ? ('Loading...')  : (
                                    <DataTable
                                        title=""
                                        columns={columns}
                                        data={dataTableFormat}
                                        defaultSortFieldId="code"
                                        pagination={true}
                                        paginationPerPage={10}
                                        paginationComponent={BootyPagination}
                                        selectableRows={false}
                                        customStyles={customStyles}
                                        onRowClicked={onRowClicked}
                                        conditionalRowStyles={conditionalRowStyles}
                                        onSort={(selectedColumn, sortDirection, sortedRows)=>{
                                            setSortedData(sortedRows);
                                        }}
                                    />
                                 )}
                                </div>
                                {dataTableFormat && (
                                <div className='row'>
                                    <div className='col-12 pt-3'>
                                        <div className=" d-flex justtify-contents-between">
                                            <div className="p-0 d-flex justify-contents-center align-items-center">
                                                <div style={{background:"#E6E6F5"}} 
                                                className="legnd"></div>
                                                <label className="form-check-label ps-2">Closed</label>
                                            </div>
                                            <div className="ps-3  d-flex justify-contents-center align-items-center">
                                                <div style={{background:"#fcc"}}
                                                className="legnd"></div>
                                                <label className="form-check-label ps-2">Not Closed</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </AppLayout>
    )
}

const mapStateToProps = (state: any) => {
    const { user } = state?.auth;
    const { isLoading, correctiveActionLogs, responsiblePersons } = state?.correctiveAction;
    const selectedClientDetails : clientType | undefined = state?.auth?.clientDetails;
  
    return {
        user,
        isLoading,
        correctiveActionLogs,
        clientId: user?.clientId,
        responsiblePersons,
        selectedClientDetails
    }
}
export default connect(mapStateToProps, null)(CorrectiveActionLog);
